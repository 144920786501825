import { shallowMount } from '@vue/test-utils';
import AnimalSoundsStart from '@/pages/animal_sounds/start';

jest.mock('vue-meta');
jest.mock('vue-i18n', () => ({
  useI18n: jest.fn(() => ({
    t: () => {},
  })),
}));


describe('AnimalSoundsStart.vue', () => {
  const global = {
    mocks: {
      animals: [{}, {}, {}],
    },
  };

  it('renders child components', () => {
    const wrapper = shallowMount(AnimalSoundsStart, { global });

    expect(wrapper.findComponent({ name: 'GameBack' }).exists()).toBe(true);
    expect(wrapper.findComponent({ name: 'GameHeadline' }).exists()).toBe(true);
    expect(wrapper.findComponent({ name: 'Button' }).exists()).toBe(true);
    expect(wrapper.findComponent({ name: 'AnimalCarousel' }).exists()).toBe(false);
    expect(wrapper.findComponent({ name: 'Animal' }).exists()).toBe(true);
  });
});
